<template>
    <div id="dashboard-layout" class="dashboard-layout">
		<Menu :editMode="editMode" />
		<div class="main-content" :class="{ 'stretch': stretchContent }">
			<router-view />
		</div>
		<ConfirmDeleteQr />
		<ConfirmDeleteCategory />
		<DownloadQr />
    </div>
</template>

<script>
import { EventBus } from '@/events_bus.js';
import Menu from '@/views/components/layout/Menu';
import ConfirmDeleteQr from "@/views/components/modals/ConfirmDeleteQr";
import ConfirmDeleteCategory from "@/views/components/modals/ConfirmDeleteCategory";
import DownloadQr from '@/views/components/modals/DownloadQr';

export default {
    name: 'DashboardLayout',
	components:{
		ConfirmDeleteQr,
		Menu,
		DownloadQr,
		ConfirmDeleteCategory
	},
	data() {
		return {
			menuVisible: false,
			wWidth: 0,
			editMode: 'mobile',
			errors: [],
			isloaded: false,
			stretchContent: false,
		}
	},
	watch: {
		errors: {
			deep: true,
			immediate: true,
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		getErrors(error) {
			console.error('ERROR', error);
			if(error && error.messages) {
				error.messages.forEach(err => {
					if(err.includes('not_authorized')) {
						this.$router.push({ name: 'login.page' })
					}
				})
			}
		},
		init() {
			this.isloaded = true;
		}
	},
	created() {
		this.onResize();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();
		
		EventBus.$on('toggle-menu', status => {
			console.log('MODE'. mode)
			if(this.editMode == 'desktop') {
				if(status) {
					this.stretchContent = true;
				} else {
					this.stretchContent = false;
				}
			}
		})
		
		this.$service.auth.getUser().then(res => {
			this.$service.auth.refreshToken().then(res => {
				localStorage.setItem('token', res.access_token);
				localStorage.setItem('me', res.user);
			}).catch(err => {
				this.getErrors(err);
			})
		});
		
		
	},
}
</script>

<style lang="scss">
.dashboard-layout {
	display: block;
	width: 100%;
	min-height: 100svh;
	background: $color-isabelline;
	.main-content {
		display: block;
		background: $color-babypowder;
		width: 100%;
		height: 100svh;
		transition: 635ms var(--ease-inout-sine);
		//padding-top: calc(var(--head) + var(--gutter));
		overflow: auto;
	}
	@media (min-width: $from-large) {
		padding: var(--minigutter);
		.main-content {
			width: calc(100% - (var(--menu) + var(--minigutter));
			margin-left: calc(var(--menu) + var(--minigutter));
			height: calc(100svh - var(--gutter));
			padding-top: 0;
			border-radius: var(--radius);
			&.stretch {
				width: calc(100% - (var(--supergutter) + var(--gutter)));
				margin-left: calc(var(--supergutter) + var(--gutter));
			}
		}
	}
}
</style>
