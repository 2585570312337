<template>
	<main id="qrcodes" class="qrcodes page">
		<div class="qrcodes__head">
			<span class="qrcodes__head__title">
				Links
			</span>
			<a href="#/links/create" class="btn filled">
				<span class="btn__label">
					Crea Nuovo
				</span>
				<span class="btn__icon">
					<i class="pi pi-plus"></i>
				</span>
			</a>
		</div>
		<div class="qrcodes__content">
			<template v-if="links && links.length">
				<div class="qrcodes__utils">
					<div class="qrcodes__utils__search">
	<!--					<SearchQr />-->
					</div>
					<div class="qrcodes__utils__filters">
						<span class="qrcodes__icon" @click="viewMode">
							<template v-if="!grid">
								<i class="pi pi-th-large"></i>
							</template>
							<template v-else>
								<i class="pi pi-list"></i>
							</template>
						</span>
					</div>
				</div>
				<div v-if="grid" class="qrcodes__list grid">
					<Card v-for="link in links" :key="link.id" :card="link" />
				</div>
				<div v-if="!grid" class="qrcodes__list list">
					<div class="list__head">
						<span class="list__head__type list__head__value">
							<span class="list__head__label">
								Type
							</span>
							<span class="list__head__sort" @click="sortList('type', typedir = !typedir)">
								<template v-if="typedir">
									<i class="pi pi-sort-alpha-down"></i>
								</template>
								<template v-else>
									<i class="pi pi-sort-alpha-up"></i>
								</template>
							</span>
						</span>
						<span class="list__head__title list__head__value">
							<span class="list__head__label">
								Titolo
							</span>
							<span class="list__head__sort" @click="sortList('name', titledir = !titledir)">
								<template v-if="titledir">
									<i class="pi pi-sort-alpha-down"></i>
								</template>
								<template v-else>
									<i class="pi pi-sort-alpha-up"></i>
								</template>
							</span>
						</span>
						<span class="list__head__info list__head__value">
							<span class="list__head__label">
								Categoria
							</span>
							<span class="list__head__sort" @click="sortList('categories', catdir = !catdir)">
								<template v-if="catdir">
									<i class="pi pi-sort-alpha-down"></i>
								</template>
								<template v-else>
									<i class="pi pi-sort-alpha-up"></i>
								</template>
							</span>
						</span>
						<span class="list__head__tags list__head__value">
							<span class="list__head__label">
								Tags
							</span>
						</span>
						<span class="list__head__date list__head__value">
							<span class="list__head__label">
								Created
							</span>
							<span class="list__head__sort" @click="sortList('createdAt', datedir = !datedir)">
								<template v-if="datedir">
									<i class="pi pi-sort-alpha-down"></i>
								</template>
								<template v-else>
									<i class="pi pi-sort-alpha-up"></i>
								</template>
							</span>
						</span>
						<span class="list__head__ctas list__head__value"></span>
					</div>
					<ListItem v-for="link in links" :key="link.id" :item="link" />
				</div>
			</template>
			<template v-else>
				<div class="qrcodes__empty">
					<span class="qrcodes__empty__label">
						Nessun link presente!
					</span>
					<a href="#/qrcodes/create" class="btn filled">
						<span class="btn__label">
							Crea Nuovo
						</span>
							<span class="btn__icon">
							<i class="pi pi-plus"></i>
						</span>
					</a>
				</div>
			</template>
		</div>
	</main>
</template>
<script>
import { EventBus } from "@/events_bus";
import Card from '@/views/components/qr/Card';
import ListItem from '@/views/components/qr/ListItem';
import SearchQr from '@/views/components/qr/SearchQr';

export default {
	name: 'QrCodes',
	components: {
		Card,
		ListItem,
		SearchQr
	},
	data() {
		return {
			links: null,
			grid: true,
			typedir: true,
			titledir: true,
			catdir: true,
			datedir: true,
		}
	},
	methods: {
		sortList(value, dir) {
			let direction = dir ? 'asc' : 'desc';
			this.$service.link.get(`sort=${value},${direction}`).then(res => {
				console.log(res)
				this.links = [...res.content];
			})
		},
		orderList() {
			this.asc = !this.asc
		},
		viewMode() {
			this.grid = !this.grid;
		},
		deleteLink(link) {
			this.$service.link.delete(link.id).then(res => {
				console.log('DELETE', res)
			})
		},
		init() {
			this.$service.link.get().then(res => {
				if(res && res.content && res.content.length) {
					this.links = [...res.content];
				}
			})
		}
	},
	mounted() {
		this.init();
		EventBus.$on('qr-deleted', () => {
			this.init();
		});
		
		EventBus.$on('search', term => {
			this.$service.link.get()
		})
	}
}
</script>
<style lang="scss">
.qrcodes {
	&__empty {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: var(--supergutter) 0;
		&__label {
			@extend %text-14;
			display: block;
			width: 100%;
			margin-bottom: var(--supergutter);
			text-align: center;
			text-transform: uppercase;
			opacity: 0.5;
		}
	}
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--supergutter);
		&__title {
			@extend %text-25;
			font-weight: 700;
		}
	}
	&__content {
		display: block;
		width: 100%;
	}
	&__utils {
		@extend %flexCenterBetween;
		width: 100%;
		margin-botom: var(--gutter);
		&__filters {
			@extend %flexCenterEnd;
			width: 50%;
		}
	}
	&__icon {
		@extend %flexCenterCenter;
		@include size(var(--gutter),var(--gutter));
		border-radius: var(--radius);
		background: $color-antiflash;
		margin-left: 10px;
		transition: 180ms linear;
		cursor: pointer;
		i {
			color: $color-gun;
			transition: 180ms linear;
		}
		&:hover {
			background: $color-bittersweet;
			i {
				color: $color-white;
			}
		}
	}
	&__list {
		display: block;
		margin-top: var(--gutter);
		&.grid {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		.list {
			&__head {
				display: flex;
				align-items: center;
				width: 100%;
				padding: var(--thirdgutter) var(--thirdgutter);
				border-bottom: 1px solid $color-silver;
				transition: 180ms linear;
				&__type {
					justify-content: center;
					width: 10%;
				}
				&__title {
					width: 25%;
					padding: 0 var(--gutter) 0 var(--minigutter);
				}
				&__info { width: 20%; }
				&__tags { width: 30%; }
				&__date { width: 12%; }
				&__ctas { width: 80px; }
				&__value {
					@extend %flexCenterStart;
				}
				&__label {
					display: block;
					@extend %text-12;
					color: rgba($color-gun, 0.5);
					margin-right: var(--minigutter);
					text-transform: uppercase;
				}
				&__sort {
					@extend %flexCenterCenter;
					@include size(var(--minigutter),var(--minigutter));
					cursor: pointer;
					i {
						font-size: var(--minigutter) !important;
						pointer-events: none;
					}
				}
			}
		}
	}
	@media (max-width: $to-large) {
		&__list {
			.list {
				&__head {
					&__title {
						width: 50%;
					}
					&__info, &__tags { display: none; }
				}
			}
		}
	}
}
</style>
